const G = {
	isMock: false,
	ENV: import.meta.env.MODE,
	platform: import.meta.env.VITE_PLATFORM,
	defaultTitle: ' ',
	isKeepAlive: false,
	projectId: 100001,
	cli_type: '1',
}

export default G

export const DOMAIN_MAP = {
	user: import.meta.env.VITE_USER_URL,
	user_admin: import.meta.env.VITE_USER_ADMIN_URL,
	1: import.meta.env.VITE_BASE_URL,
	2: import.meta.env.VITE_BASE_URL_V2,
	node: import.meta.env.VITE_NODE_URL,
	log: import.meta.env.VITE_LOG_URL,
	ass: import.meta.env.VITE_ASS_URL,
	review: import.meta.env.VITE_REVIEW_URL,
	question: import.meta.env.VITE_QUESTION_URL,
	question2: import.meta.env.VITE_QUESTION_URL2,
}

export const HS_ORG_CODE = 'UhUEYay8QuxxqhuL'
